import { environments, envs } from '@sentry/shared';
import { ignoreUnhandledRequests } from './unhandled';

export const ims = (path: string) =>
  new URL(path, 'https://ims-na1-stg1.adobelogin.com').toString();

export const stargate = (path: string) =>
  new URL(path, 'https://auth-stg1.services.adobe.com').toString();

// TODO: Fix the @sentry/shared repo exports
const { default: getEnvironment, ENVIRONMENTS } = environments;

export async function setupServiceWorker() {
  const searchParams = new URLSearchParams(window.location.search);
  if (
    getEnvironment(envs.getAppEnv).isEnvironment(ENVIRONMENTS.local) &&
    searchParams.get('disable_local_msw') !== 'true'
  ) {
    const { worker } = await import('./browser');

    await worker.start({
      onUnhandledRequest(request) {
        return ignoreUnhandledRequests(request);
      },
    });
  }
}
