import { ReactiveElement, css, html } from 'lit';
import { customElement, queryAsync } from 'lit/decorators';

import '@spectrum-web-components/button/sp-button';

import '$components/country-selector';
import '$components/date-of-birth';
import '$components/loading-wrapper';
import '$components/marketing-consent';
import '$components/profile';
import '$components/sp-custom-select';
import '$components/text-field';

import { SentryPage } from '$components/template';
import { i18n } from '$locales/helpers';
import { flex } from '$shared/mixins/flex';
import { labelStyling } from '$shared/mixins/label';
import { ACCOUNT_TYPE } from '$shared/types/account';

import { InteractionEvent } from '$scripts/analytics/decorators';
import { CountryId } from '$shared/types/countries';
import { Controller } from './controller';
import { events } from './events';
import { PageState } from './state';

type PageController = Controller;

export const PAGE_TAGNAME = 'complete-account';
@customElement(PAGE_TAGNAME)
export class CompleteAccount extends SentryPage<PageController, PageState> {
  static styles = [
    css`
      sp-field-label {
        ${labelStyling()}
      }

      .page-wrapper,
      #complete-account-form {
        ${flex().direction('column').gap(400).build()}
      }

      .page-wrapper {
        position: absolute;
      }

      .form-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 6px 24px 1fr;
        grid-template-areas:
          'first-name-textfield last-name-textfield'
          '. .'
          'dob-container dob-container'
          'dob-container dob-container';

        margin-bottom: -15px;
      }

      #dob-container {
        grid-area: dob-container;
      }

      #first-name-textfield {
        grid-area: first-name-textfield;
      }

      #last-name-textfield {
        grid-area: last-name-textfield;
      }

      .form-container {
        align-items: start;
      }

      #complete-account-button {
        width: fit-content;
        align-self: flex-end;
      }
    `,
  ];

  @queryAsync('#marketing-consent')
  getMarketingConsentElement?: Promise<ReactiveElement>;

  @InteractionEvent('SignupClick')
  onSubmit(e: any) {
    e.preventDefault();
    this.controller.handleSubmit(e);
  }

  @InteractionEvent(
    (_: CompleteAccount, field: string) => events.fieldEnter[field],
    true
  )
  setFormValue(field: string, value: string | number) {
    this.state.setFormValue({ [field]: value });
  }

  @InteractionEvent('MonthOfBirthChange', true)
  setMonthOfBirth(month: number) {
    this.state.setMonthOfBirth(month);
  }

  @InteractionEvent('YearOfBirthChange', true)
  setYearOfBirth(year: number) {
    this.state.setYearOfBirth(year);
  }

  @InteractionEvent('CountryChange', true)
  setSelectedCountry(country: CountryId) {
    this.state.setSelectedCountry(country);
  }

  render() {
    return html`
      <div class="page-wrapper">
        <heading-tag as="h1" size="L">
          ${i18n().formatMessage({
            id: 'complete-account.title.heading',
            defaultMessage: 'Complete account',
          })}
        </heading-tag>
        <profile-card
          accountType=${ACCOUNT_TYPE.individual}
          email="super_user@gmail.com"
        ></profile-card>

        <form id="complete-account-form" @submit=${this.onSubmit}>
          <div class="form-container">
            <text-field
              id="first-name-textfield"
              data-id="first-name-textfield"
              @textfield-input=${(e: any) =>
                this.setFormValue('firstName', e.detail.value)}
              .warningMessage=${this.state.validations.firstName ?? ''}
              .initialValue=${this.state.form.firstName ?? ''}
              .isValid=${this.state.hasSubmitBeenClicked
                ? !this.state.validations.firstName
                : null}
            >
              <p slot="label">
                ${i18n().formatMessage({
                  id: 'complete-account.label.first-name',
                  defaultMessage: 'First name',
                })}
              </p>
            </text-field>
            <text-field
              id="last-name-textfield"
              data-id="last-name-textfield"
              @textfield-input=${(e: any) =>
                this.setFormValue('lastName', e.detail.value)}
              .warningMessage=${this.state.validations.lastName ?? ''}
              .initialValue=${this.state.form.lastName ?? ''}
              .isValid=${this.state.hasSubmitBeenClicked
                ? !this.state.validations.lastName
                : null}
            >
              <p slot="label">
                ${i18n().formatMessage({
                  id: 'complete-account.label.last-name',
                  defaultMessage: 'Last name',
                })}
              </p>
            </text-field>

            <date-of-birth
              id="dob-container"
              .handleMonthChange=${(e: any) =>
                this.setMonthOfBirth(+e.detail.value)}
              .handleYearChange=${(e: any) =>
                this.setYearOfBirth(+e.detail.value)}
              .yobWarningMessage=${this.state.validations.yearOfBirth ?? ''}
              .initialValue=${{
                month: this.state.form.monthOfBirth,
                year: this.state.form.yearOfBirth,
              } || {}}
              .isValid=${this.state.hasSubmitBeenClicked
                ? !this.state.validations.yearOfBirth
                : null}
            />
          </div>

          <div class="marketing-consent-container">
            <country-selector
              @country-change=${(e: CustomEvent) => {
                this.state.setAgeOfConsent(e.detail.ageOfConsent);
                this.setSelectedCountry(e.detail.value);
              }}
              .countries=${this.state.countries}
              .selectedCountry=${this.state.selectedCountry}
            ></country-selector>

            <!-- "canUserConsent" is used to determine whether to display the consent element or not -->
            <marketing-consent
              .country=${this.state.selectedCountry}
              .canUserConsent=${this.state.isTheUserOldEnoughToConsent}
              @marketing-consent-change=${this.controller
                .saveMarketingConsentElement}
              .hasUserConsented=${this.state.hasUserConsented}
              .setUserConsent=${(value: boolean) =>
                this.state.setHasUserConsented(value)}
              .consentProfile=${this.state.consentProfile}
              id="marketing-consent"
            ></marketing-consent>
          </div>

          <sp-button
            ?disabled=${this.state.isSubmitButtonDisabled}
            id="complete-account-button"
            data-id="complete-account-button"
            type="submit"
          >
            ${i18n().formatMessage({
              id: 'complete-account.button.continue',
              defaultMessage: 'Continue',
            })}
          </sp-button>
        </form>
      </div>
    `;
  }
}
