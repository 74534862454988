import { socialCompleteAccount } from '$api/social-api';
import { MarketingConsentChangeEvent } from '$components/marketing-consent/types';
import { OutcomeListener, PageController } from '$scripts/base-controller';
import { Outcome } from '$scripts/outcome';
import { getApplicationState } from '$store/application-store';
import { getCookiesState } from '$store/cookies-store';
import { getBirthDateFromMonthAndYear } from '$store/helpers';
import { SentryError, convertError } from '@sentry/shared';
import { PageState } from './state';

export class UserCreatedOutcome extends Outcome<{
  user: Record<string, string | number>;
  token: string;
}> {}
export class UserCreationFailedOutcome extends Outcome<{
  error: SentryError;
}> {}
export class AccessDeniedOutcome extends Outcome<{}> {}

export class CloseModalOutcome extends Outcome<{}> {}

export type PageOutcome =
  | UserCreatedOutcome
  | UserCreationFailedOutcome
  | AccessDeniedOutcome
  | CloseModalOutcome;

export class Controller extends PageController<PageOutcome, PageState> {
  marketingConsentElement: string = '';

  @OutcomeListener()
  async accessDenied() {
    return new AccessDeniedOutcome({});
  }

  saveMarketingConsentElement = (e: MarketingConsentChangeEvent) => {
    this.marketingConsentElement = e.detail.html;
  };

  @OutcomeListener()
  handleSuccessfulSubmit(user: Record<string, string | number>, token: string) {
    return new UserCreatedOutcome({ user, token });
  }

  @OutcomeListener()
  handleErrorSubmit(err: unknown) {
    return new UserCreationFailedOutcome({ error: convertError(err) });
  }
  async handleSubmit(e: any) {
    const { queryState } = await getApplicationState();
    const { checkCoppaCompliance } = getCookiesState();

    e.preventDefault();
    try {
      this.state.setHasSubmitted(true);
      const { isFormValid } = this.state.validateForm();

      if (
        isFormValid &&
        !checkCoppaCompliance(
          this.state.form.monthOfBirth,
          this.state.form.yearOfBirth
        )
      ) {
        this.accessDenied();
      }

      if (!isFormValid) {
        return;
      }

      const dob = getBirthDateFromMonthAndYear(
        this.state.form.monthOfBirth,
        this.state.form.yearOfBirth
      );
      const day = dob.getDate();

      const { firstName, lastName } = this.state.form;
      const dateOfBirth = {
        day,
        month: this.state.form.monthOfBirth,
        year: this.state.form.yearOfBirth,
      };

      const marketingConsent = {
        text: this.marketingConsentElement,
        accepted: this.state.hasUserConsented,
      };

      const token = this.state.token;

      const request = {
        fedToken: token,
        termsOfUseAcceptances: [
          {
            name: 'ADOBE_MASTER',
            accepted: true,
          },
        ],
        marketingConsent,
        firstName,
        lastName,
        countryCode: this.state.selectedCountry,
        dateOfBirth,
        locale: queryState.locale,
      };

      const user = await socialCompleteAccount(queryState, request);

      return this.handleSuccessfulSubmit(user, token);
    } catch (err: any) {
      return this.handleErrorSubmit(err);
    }
  }
}
