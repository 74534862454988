import { QueryState, queryState as qs } from '$scripts/query-state';
import { getIMSApi } from '.';

import { PATHNAMES } from './constants';

export async function getQueryStateId(
  client_id: string,
  queryState: QueryState
): Promise<{ id: string; queryState: string }> {
  const ims = await getIMSApi();

  const formattedQueryString = new URLSearchParams(qs.toString(queryState));

  return await ims
    .url(PATHNAMES.queryState)
    .query({ id: client_id, queryState: formattedQueryString.toString() })
    .put()
    .json();
}
