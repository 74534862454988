import { getCountryConsentType } from '$components/marketing-consent/helpers';
import { AccessDeniedPage } from '$pages/access-denied';
import { completeAccount } from '$pages/variants/complete-account';
import {
  AccessDeniedOutcome,
  CloseModalOutcome,
  UserCreatedOutcome,
  UserCreationFailedOutcome,
} from '$pages/variants/complete-account/controller';
import { AppEvents } from '$scripts/events/events';
import { CriticalOutcome } from '$scripts/outcome';
import { getCookiesState } from '$store/cookies-store';
import { getCountriesState } from '$store/countries-store';
import { criticalErrorFlow } from '..';
import { FlowConfig as CompleteAccountConfig } from './schema';

type Fields = {
  firstName: string;
  lastName: string;
};

export async function completeAccountFlow(
  events: AppEvents,
  config: CompleteAccountConfig,
  params: {
    fields: Partial<Fields>;
    token: string;
  }
): Promise<any> {
  const { countries, nearCountryId, nearCountryAgeOfConsent } =
    await getCountriesState();

  const { isCoppaCompliant } = getCookiesState();

  if (!isCoppaCompliant()) {
    const outcome = await AccessDeniedPage.create({});
    return outcome;
  }

  const outcome = await completeAccount.create({
    countries,
    isTheUserOldEnoughToConsent: true,
    selectedCountry: nearCountryId,
    ageOfConsent: nearCountryAgeOfConsent,
    consentProfile: config.consentProfile,
    form: {
      firstName: params.fields.firstName || '',
      lastName: params.fields.lastName || '',
      monthOfBirth: 0,
      yearOfBirth: 0,
    },
    token: params.token,
    hasUserConsented: !(
      getCountryConsentType(nearCountryId, config.consentProfile) === 'explicit'
    ),
  });

  if (outcome.of(UserCreatedOutcome)) {
    // User login
    // Give token
    return outcome;
  }

  if (outcome.of(UserCreationFailedOutcome)) {
    const { error } = outcome.getValue();
    return criticalErrorFlow(events, new CriticalOutcome(error));
  }

  if (outcome.of(AccessDeniedOutcome)) {
    const outcome = await AccessDeniedPage.create({});
    return outcome;
  }

  if (outcome.of(CloseModalOutcome)) {
    return outcome;
  }

  return outcome;
}
